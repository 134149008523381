import React from "react"
import { Link } from "gatsby"
import FotoKontigoDesktop from "@components/pageCasosdeExito/image/imageCards/FotoKontigoDesktop.png"
import FotoKontigoMobile from "@components/pageCasosdeExito/image/imageCards/FotoKontigoMobile.png"
import FotoNetcallPeruDesktop from "@components/pageCasosdeExito/image/imageCards/FotoNetcallDesktop.png"
import FotoNetcallPeruMobile from "@components/pageCasosdeExito/image/imageCards/FotoNetcallMobile.png"
import FotoRecuperaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRecuperaDesktop.png"
import FotoRecuperaMobile from "@components/pageCasosdeExito/image/imageCards/FotoRecuperaMobile.png"
import FotoCulquiDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCulquiDesktop.png"
import FotoCulquiMobile from "@components/pageCasosdeExito/image/imageCards/FotoCulquiMobile.png"
import FotoMorneseDesktop from "@components/pageCasosdeExito/image/imageCards/FotoMorneseDesktop.png"
import FotoMorneseMobile from "@components/pageCasosdeExito/image/imageCards/FotoMorneseMobile.png"
import FotoCostagasDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCostagasDesktop.png"
import FotoCostagasMobile from "@components/pageCasosdeExito/image/imageCards/FotoCostagasMobile.png"
import FotoGualdaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoGualdaDesktop.png"
import FotoGualdaMobile from "@components/pageCasosdeExito/image/imageCards/FotoGualdaMobile.png"
import FotoCasaRosselloDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRosselloDesktop.png"
import FotoCasaRosselloMobile from "@components/pageCasosdeExito/image/imageCards/FotoRosselloMobile.png"
import FotoCafaeDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCafaeDesktop.png"
import FotoCafaeMobile from "@components/pageCasosdeExito/image/imageCards/FotoCafaeMobile.png"
import FotoEnseñaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoEnseñaDesktop.png"
import FotoEnseñaMobile from "@components/pageCasosdeExito/image/imageCards/FotoEnseñaMobile.png"
import FotoRoyalEnfieldDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRoyalDesktop.png"
import FotoRoyalEnfieldMobile from "@components/pageCasosdeExito/image/imageCards/FotoRoyalMobile.png"
import FotoAvivaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAvivaDesktop.png"
import FotoAvivaMobile from "@components/pageCasosdeExito/image/imageCards/FotoAvivaMobile.png"
import FotoCobraDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCobraDesktop.png"
import FotoCobraMobile from "@components/pageCasosdeExito/image/imageCards/FotoCobraMobile.png"
import FotoAstaraDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAstaraDesktop.png"
import FotoAstaraMobile from "@components/pageCasosdeExito/image/imageCards/FotoAstaraMobile.png"
import FotoIttsaBusDesktop from "@components/pageCasosdeExito/image/imageCards/FotoIttsaBusDesktop.png"
import FotoIttsaBusMobile from "@components/pageCasosdeExito/image/imageCards/FotoIttsaBusMobile.png"
import FotoSavarDesktop from "@components/pageCasosdeExito/image/imageCards/FotoSavarDesktop.png"
import FotoSavarMobile from "@components/pageCasosdeExito/image/imageCards/FotoSavarMobile.png"
import FotoCepebanDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCepebanDesktop.png"
import FotoCepebanMobile from "@components/pageCasosdeExito/image/imageCards/FotoCepebanMobile.png"
import FotoSynlabDesktop from "@components/pageCasosdeExito/image/imageCards/FotoSynlabDesktop.png"
import FotoSynlabMobile from "@components/pageCasosdeExito/image/imageCards/FotoSynlabMobile.png"
import FotoAunaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAunaDesktop.png"
import FotoProEmpresaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoProEmpresaDesktop.png"
import FotoCyCDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCyCDesktop.png"
import FotoEscampaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoEscampaDesktop.png"
import FotoAunaMobile from "@components/pageCasosdeExito/image/imageCards/FotoAunaMobile.png"
import FotoProEmpresaMobile from "@components/pageCasosdeExito/image/imageCards/FotoProEmpresaMobile.png"
import FotoCyCMobile from "@components/pageCasosdeExito/image/imageCards/FotoCyCMobile.png"
import FotoEscampaMobile from "@components/pageCasosdeExito/image/imageCards/FotoEscampaMobile.png"

import customer from "@components/pageCasosdeExito/components/customer.jsx"
import TitleUnderline from "./titleUnderline"

const LinkCasos = ({ link }) => (
  <div style={{ marginTop: "20px" }}>
    <Link to={link} className="button-link-casos">
      <span>
        Conocer más
      </span>
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z" />
      </svg>
    </Link>
    <p className="collaboration">Con colaboración de:</p>
  </div>
)

const ContenedorDosFilasLeft = ({ data1, data2, data3 }) => {
  return (
    <div className="flex-container-casosexito">
      {/* una sola fila */}
      <section className="rows-container-testimony">
        <div className="card-culqui">
          <section>
            <img
              className="foto hide-component-desktop"
              src={data1.foto1}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={data1.foto2}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="descripcion-culqui">
            <p className="tag">{data1.tag}</p>
            <p className="descripcion">
              {data1.description}
            </p>
            <div>
              <LinkCasos link={data1.link} />
              <p className="name-cliente">{data1.name}</p>
              <p>{data1.job}</p>
            </div>
          </section>
        </div>
      </section>
      {/* Dos filas */}
      <div>
        {/* columna */}
        <section className="rows-container-testimony">
          <section className="card-recupera">
            <section>
              <p className="tag">{data2.tag}</p>
              <p className="descripcion">
                {data2.description}
              </p>
              <div>
                <LinkCasos link={data2.link} />
                <p className="name-cliente">{data2.name}</p>
                <p>{data2.job}</p>
              </div>
            </section>
            <section className="foto-container">
              <img
                className="foto hide-component-desktop"
                src={data2.foto1}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={data2.foto2}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </section>
        {/* columna */}
        <section
          className="rows-container-testimony"
          style={{ marginBottom: "20px" }}
        >
          <section className="card-netcall">
            <section>
              <img
                className="foto hide-component-desktop"
                src={data3.foto1}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={data3.foto2}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section>
              <p className="tag">{data3.tag}</p>
              <p className="descripcion">
                {data3.description}
              </p>
              <div>
                <LinkCasos link={data3.link} />
                <p className="name-cliente">{data3.name}</p>
                <p>{data3.job}</p>
              </div>
            </section>
          </section>
        </section>
      </div>
    </div>
  )
}

const ContenedorUnaFilaLeft = ({ data }) => {
  return (
    <section className="rows-container-testimony">
      <section className="component-card">
        <section className="box-img-testimony">
          <img
            className="foto hide-component-desktop"
            src={data.foto1}
            alt="foto"
            loading="lazy"
          />
          <img
            className="foto hide-component-mobile"
            src={data.foto2}
            alt="foto"
            loading="lazy"
          />
        </section>
        <section className="box-text-testimony">
          <p className="tag">
            {data.tag}
          </p>
          <p className="descripcion">
            {data.description}
          </p>
          <div>
            <LinkCasos link={data.link} />
            <p className="name-cliente">{data.name}</p>
            <p>{data.job}</p>
          </div>
        </section>
      </section>
    </section>
  )
}

const ContenedorUnaFilaRight = ({ data }) => {
  return (
    <section className="rows-container-testimony">
      <section className="component-card-mornese component-card">
        <section className="box-text-testimony-mornese box-text-testimony">
          <p className="tag">
            {data.tag}
          </p>
          <p className="descripcion">
            {data.description}
          </p>
          <div>
            <LinkCasos link={data.link} />
            <p className="name-cliente">{data.name}</p>
            <p>{data.job}</p>
          </div>
        </section>
        <section className="box-img-testimony">
          <img
            className="foto hide-component-desktop"
            src={data.foto1}
            alt="foto"
            loading="lazy"
          />
          <img
            className="foto hide-component-mobile"
            src={data.foto2}
            alt="foto"
            loading="lazy"
          />
        </section>
      </section>
    </section>
  )
}

const ContenedorDosFilasRight = ({ data1, data2, data3 }) => {
  return (
    < div className="flex-container-casosexito reverse" >
      {/* una sola fila */}
      < section className="rows-container-testimony" >
        <div className="card-culqui">
          <section>
            <img
              className="foto hide-component-desktop"
              src={data1.foto1}
              alt="foto"
              loading="lazy"
            />
            <img
              className="foto hide-component-mobile"
              src={data1.foto2}
              alt="foto"
              loading="lazy"
            />
          </section>
          <section className="descripcion-culqui">
            <p className="tag">
              {data1.tag}
            </p>
            <p className="descripcion">
              {data1.description}
            </p>
            <div>
              <LinkCasos link={data1.link} />
              <p className="name-cliente">{data1.name}</p>
              <p>{data1.job}</p>
            </div>
          </section>
        </div>
      </section >
      {/* Dos filas */}
      < div >
        {/* columna */}
        < section className="rows-container-testimony" >
          <section className="card-recupera">
            <section>
              <p className="tag">
                {data2.tag}
              </p>
              <p className="descripcion">
                {data2.description}
              </p>
              <div>
                <LinkCasos link={data2.link} />
                <p className="name-cliente">{data2.name}</p>
                <p>{data2.job}</p>
              </div>
            </section>
            <section>
              <img
                className="foto hide-component-desktop"
                src={data2.foto1}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={data2.foto2}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </section >
        {/* columna */}
        < section
          className="rows-container-testimony"
          style={{ marginBottom: "20px" }}
        >
          <section className="card-netcall">
            <section>
              <img
                className="foto hide-component-desktop"
                src={data3.foto1}
                alt="foto"
                loading="lazy"
              />
              <img
                className="foto hide-component-mobile"
                src={data3.foto2}
                alt="foto"
                loading="lazy"
              />
            </section>
            <section>
              <p className="tag">
                {data3.tag}
              </p>
              <p className="descripcion">
                {data3.description}
              </p>
              <div>
                <LinkCasos link={data3.link} />
                <p className="name-cliente">{data3.name}</p>
                <p>{data3.job}</p>
              </div>
            </section>
          </section>
        </section >
      </div >
    </div >
  )
}


const CardsCasosExito = ({ data, location }) => (

  <section className="section-cardscasos">
    <p className="text-title-start-postulacion" style={{ textAlign: "center" }}>
      <TitleUnderline underline="Casos de éxito" />
    </p>
    <p className="text-casosexito">
      Estos son algunos de nuestros más importantes casos de éxito
    </p>
    <section className="container-cards-casosexito">
      <ContenedorDosFilasLeft data1={customer.culqui} data2={customer.auna} data3={customer.proempresa} />
      <ContenedorUnaFilaRight data={customer.astara} />
      <ContenedorUnaFilaLeft data={customer.savar} />    
      <ContenedorDosFilasRight data1={customer.civa} data2={customer.synlab} data3={customer.royal} />
      <ContenedorUnaFilaLeft data={customer.rossello } />    
      <ContenedorUnaFilaRight data={customer.cepeban} />
      <ContenedorDosFilasLeft data1={customer.ittsabus} data2={customer.aviva} data3={customer.cafae } />
      <ContenedorUnaFilaRight data={customer.cobra } />
      <ContenedorUnaFilaLeft data={customer.kontigo } />    
      <ContenedorDosFilasRight data1={customer.mornese} data2={customer.cyc} data3={customer.netcall} />
      <ContenedorUnaFilaLeft data={customer.costagas} />    
      <ContenedorUnaFilaRight data={customer.escampa} />
      <ContenedorDosFilasLeft data1={customer.gualda} data2={customer.recupera}  data3={customer.enseña} />
    </section>

    <div className="cardscasosexito">
      {data.map((elemento, index) => {
        return (
          <section id={elemento} key={index} className="style-cardscasos">
            <img loading="lazy" src={elemento.image} alt="casos-exito" />
            <p className="tag">{elemento.tag}</p>
            <p className="textcards-casosexito">{elemento.textbody}</p>
            <Link to={`${elemento.link}${location.search}`} className="button-link-casos" style={{ textAlign: 'center' }}>
              <span>
                Conocer más
              </span>
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z" />
              </svg>
            </Link>
          </section>
        )
      })}
    </div>
  </section>
)
export default CardsCasosExito
