import FotoAstaraDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAstaraDesktop.png"
import FotoAstaraMobile from "@components/pageCasosdeExito/image/imageCards/FotoAstaraMobile.png"
import FotoAvivaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAvivaDesktop.png"
import FotoAvivaMobile from "@components/pageCasosdeExito/image/imageCards/FotoAvivaMobile.png"
import FotoAunaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoAunaDesktop.png"
import FotoAunaMobile from "@components/pageCasosdeExito/image/imageCards/FotoAunaMobile.png"
import FotoCafaeDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCafaeDesktop.png"
import FotoCafaeMobile from "@components/pageCasosdeExito/image/imageCards/FotoCafaeMobile.png"
import FotoCasaRosselloDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRosselloDesktop.png"
import FotoCasaRosselloMobile from "@components/pageCasosdeExito/image/imageCards/FotoRosselloMobile.png"
import FotoCepebanDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCepebanDesktop.png"
import FotoCepebanMobile from "@components/pageCasosdeExito/image/imageCards/FotoCepebanMobile.png"
import FotoCivaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCivaDesktop.png"
import FotoCivaMobile from "@components/pageCasosdeExito/image/imageCards/FotoCivaMobile.png"
import FotoCobraDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCobraDesktop.png"
import FotoCobraMobile from "@components/pageCasosdeExito/image/imageCards/FotoCobraMobile.png"
import FotoCostagasDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCostagasDesktop.png"
import FotoCostagasMobile from "@components/pageCasosdeExito/image/imageCards/FotoCostagasMobile.png"
import FotoCulquiDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCulquiDesktop.png"
import FotoCulquiMobile from "@components/pageCasosdeExito/image/imageCards/FotoCulquiMobile.png"
import FotoCyCDesktop from "@components/pageCasosdeExito/image/imageCards/FotoCyCDesktop.png"
import FotoCyCMobile from "@components/pageCasosdeExito/image/imageCards/FotoCyCMobile.png"
import FotoEnseñaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoEnseñaDesktop.png"
import FotoEnseñaMobile from "@components/pageCasosdeExito/image/imageCards/FotoEnseñaMobile.png"
import FotoEscampaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoEscampaDesktop.png"
import FotoEscampaMobile from "@components/pageCasosdeExito/image/imageCards/FotoEscampaMobile.png"
import FotoGualdaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoGualdaDesktop.png"
import FotoGualdaMobile from "@components/pageCasosdeExito/image/imageCards/FotoGualdaMobile.png"
import FotoIttsaBusDesktop from "@components/pageCasosdeExito/image/imageCards/FotoIttsaBusDesktop.png"
import FotoIttsaBusMobile from "@components/pageCasosdeExito/image/imageCards/FotoIttsaBusMobile.png"
import FotoKontigoDesktop from "@components/pageCasosdeExito/image/imageCards/FotoKontigoDesktop.png"
import FotoKontigoMobile from "@components/pageCasosdeExito/image/imageCards/FotoKontigoMobile.png"
import FotoMorneseDesktop from "@components/pageCasosdeExito/image/imageCards/FotoMorneseDesktop.png"
import FotoMorneseMobile from "@components/pageCasosdeExito/image/imageCards/FotoMorneseMobile.png"
import FotoNetcallPeruDesktop from "@components/pageCasosdeExito/image/imageCards/FotoNetcallDesktop.png"
import FotoNetcallPeruMobile from "@components/pageCasosdeExito/image/imageCards/FotoNetcallMobile.png"
import FotoProEmpresaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoProEmpresaDesktop.png"
import FotoProEmpresaMobile from "@components/pageCasosdeExito/image/imageCards/FotoProEmpresaMobile.png"
import FotoRecuperaDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRecuperaDesktop.png"
import FotoRecuperaMobile from "@components/pageCasosdeExito/image/imageCards/FotoRecuperaMobile.png"
import FotoRoyalEnfieldDesktop from "@components/pageCasosdeExito/image/imageCards/FotoRoyalDesktop.png"
import FotoRoyalEnfieldMobile from "@components/pageCasosdeExito/image/imageCards/FotoRoyalMobile.png"
import FotoSavarDesktop from "@components/pageCasosdeExito/image/imageCards/FotoSavarDesktop.png"
import FotoSavarMobile from "@components/pageCasosdeExito/image/imageCards/FotoSavarMobile.png"
import FotoSynlabDesktop from "@components/pageCasosdeExito/image/imageCards/FotoSynlabDesktop.png"
import FotoSynlabMobile from "@components/pageCasosdeExito/image/imageCards/FotoSynlabMobile.png"


const customer = {
    culqui: {
        tag: 'Fintech | Telemarketing',
        description: 'Culqi, empresa de pagos digitales, integra por APIs los leads de su CRM y los llama automáticamente con Beex. Resultados: +65% en contactabilidad y x8 sus ventas de mpos al mes.',
        link: '/casos-de-exito/culqi/',
        name: 'Paul Vera',
        job: 'Jefe de telemarketing de Culqi',
        foto1: FotoCulquiDesktop,
        foto2: FotoCulquiMobile
    },
    auna: {
        tag: 'Salud | Atención al cliente',
        description: 'AUNA, cred de clínicas y centros de bienestar en Latam, ha incrementado en 5x tu tasa de conversión con ayuda de Beex, automatizando su gestión y seguimiento de leads por WhatsApp.',
        link: '/casos-de-exito/auna/',
        name: 'Graciela Zapater Espejo',
        job: 'Especialista Digital en AUNA',
        foto1: FotoAunaDesktop,
        foto2: FotoAunaMobile
    },
    proempresa: {
        tag: 'Banca, Seguros y Finanzas | Atención al cliente',
        description: 'Financiera Proempresa, ha aumentado su venta de productos financieros en +30% con la ayuda de Beex, optimizando su gestión de leads y masificando su comunicación con campañas masivas por WhatsApp.',
        link: '/casos-de-exito/proempresa/',
        name: 'Nancy Rodriguez Siancas',
        job: 'Marketing Leader',
        foto1: FotoProEmpresaDesktop,
        foto2: FotoProEmpresaMobile
    },
    astara: {
        tag: 'Concesionarias | Marketing y ventas',
        description: ' Astara, concesionaria líder que representa a marcas como Kia, Mitsubishi y Peugeot, ha logrado automatizar sus procesos de ventas y remarketing con WhatsApp Business API, aumentando en 3x sus ventas digitales.',
        link: '/casos-de-exito/astara/',
        name: 'Jonny Castillo',
        job: 'Jefe Comercial de Astara',
        foto1: FotoAstaraDesktop,
        foto2: FotoAstaraMobile
    },
    savar: {
        tag: 'Logística | Atención al cliente',
        description: 'Savar, compañía de logística especialista en soluciones Door to Door, ha automatizado su proceso de notificaciones de estado de envíos por WhatsApp, mejorando en +20% la satisfacción de sus clientes.',
        link: '/casos-de-exito/savar/',
        name: 'Dinkar Rios Macedo',
        job: 'Jefe de Sistemas de Savar',
        foto1: FotoSavarDesktop,
        foto2: FotoSavarMobile
    },
    civa: {
        tag: 'Transporte | Atención al cliente | Marketing y ventas',
        description: 'CIVA, una de las marcas más reconocidas en el Perú, del rubro transportes, ha logrado automatizar las comunicaciones con sus pasajeros por canal telefónico, correo y redes sociales. Reduciendo en 20% sus tiempos de respuesta y en más de 1min su TMo.',
        link: '/casos-de-exito/civa/',
        name: 'Ángel Rodas',
        job: 'Coordinador de Ventas Digital de CIVA',
        foto1: FotoCivaDesktop,
        foto2: FotoCivaMobile
    },
    synlab: {
        tag: 'Salud | Atención al cliente',
        description: 'SYNLAB Perú, operación peruana del laboratorio clínico más grande de Europa, ha mejorado su tiempo de primera respuesta en 95% gracias a Beex, logrando centralizar y automatizar su servicio al cliente.',
        link: '/casos-de-exito/synlab/',
        name: 'Yesenia Sánchez Rodriguez',
        job: 'Jefa de Servicio al Cliente en SYNLAB',
        foto1: FotoSynlabDesktop,
        foto2: FotoSynlabMobile
    },
    royal: {
        tag: 'Concesionarias | Marketing y ventas',
        description: 'Royald Enfield, marca de motocicletas reconocida a nivel mundial, centraliza las comunicaciones digitales de sus tiendas y franquicias con nuestra plataforma de Beex, logrando aumentar sus leads bien prospectados en +27%.',
        link: '/casos-de-exito/royal-enfield/',
        name: 'Liliana López',
        job: 'Jefe de Marketing de Royal Enfield',
        foto1: FotoRoyalEnfieldDesktop,
        foto2: FotoRoyalEnfieldMobile
    },
    rossello: {
        tag: 'Ecommerce | Marketing y ventas',
        description: ' Casa Rosselló, ha centralizado sus comunicaciones de WhatsApp, Livechat, Instagram y más con nuestra plataforma de Beex; logrando aumentar en mas del 75% sus leads bien prospectados desde canales digitales.',
        link: '/casos-de-exito/casa-rossello/',
        name: 'Marlen Vega',
        job: 'Jefa de Marketing de Casa Rosselló',
        foto1: FotoCasaRosselloDesktop,
        foto2: FotoCasaRosselloMobile
    },
    cepeban: {
        tag: 'Educación | Marketing y ventas',
        description: ' CEPEBAN, el principal instituto especializado en banca y finanzas del Perú, ha sido capaz de mejorar su tasa de conversión en +27% en su canal digital, implementando chatbots de prospección por WhatsApp.',
        link: '/casos-de-exito/cepeban/',
        name: 'Alan De La Cruz',
        job: 'Jefe de Sistemas de CEPEBAN',
        foto1: FotoCepebanDesktop,
        foto2: FotoCepebanMobile
    },
    ittsabus: {
        tag: 'Transporte | Atención al cliente',
        description: 'IttsaBus, importante empresa de transporte terreste en Perú, migró su Contact Center a la nube con Beex.Con esto, logró mejorar su atención al cliente y la satisfacción de sus usuarios en reprogramación de viajes en 22%.',
        link: '/casos-de-exito/ittsa-bus/',
        name: 'Iris Pérez Medina',
        job: 'Coordinadora de Marketing y Servicio al Cliente',
        foto1: FotoIttsaBusDesktop,
        foto2: FotoIttsaBusMobile
    },
    aviva: {
        tag: 'Salud | Atención al cliente',
        description: ' Clínica Aviva, ha logrado automatizar su proceso de gestión de citas médicas con Beex. Ahora sus pacientes son capaces de agendar, reagendar y cancelar sus citas hasta 30% más rápido por el canal que más usan, WhatsApp.',
        link: '/casos-de-exito/clinica-aviva/',
        name: 'Luis Ordinola Díaz',
        job: 'Coordinador de Mejora de Procesos de Clínica Aviva',
        foto1: FotoAvivaDesktop,
        foto2: FotoAvivaMobile
    },
    cafae: {
        tag: 'Gobierno | Atención al cliente | Marketing y ventas',
        description: ' Conoce como CAFAE-SE, logra gestionar eficientemente interacciones por WhatsApp, atendiendo el 60% de sus consultas sin intervención de agentes.',
        link: '/casos-de-exito/cafae-se/',
        name: 'Johans Herencia',
        job: 'Responsable de Marketing Digital en CAFAE-SE',
        foto1: FotoCafaeDesktop,
        foto2: FotoCafaeMobile
    },
    cobra: {
        tag: 'Telecomunicaciones | Atención al cliente',
        description: ' Grupo Cobra, ha mejorado en +20% su contactabilidad en comparación al canal telefónico, con la API de WhatsApp Business. Conoce como es capaz de contactar con sus usuarios de manera efectiva con Beex.',
        link: '/casos-de-exito/grupo-cobra/',
        name: 'Luis Pasco Ramirez',
        job: 'Jefe de Operaciones en Grupo Cobra',
        foto1: FotoCobraDesktop,
        foto2: FotoCobraMobile
    },
    kontigo: {
        tag: 'Fintech | Atención al cliente',
        description: 'Kontigo, es capaz de cerrar el 98% de sus casos de atención al cliente en un primer contacto. Descubre cómo esta Fintech lo logra con Beex.',
        link: '/casos-de-exito/kontigo/',
        name: 'Analucía Siguas Velásquez',
        job: 'Gerente de Marketing y Producto en GMoney',
        foto1: FotoKontigoDesktop,
        foto2: FotoKontigoMobile
    },
    mornese: {
        tag: 'Contact Center | Cobranzas',
        description: 'Mornese, con operaciones de Contact Center a nivel global, ha logrado automatizar sus procesos con las integraciones nativas y personalizadas de Beex. Conoce como ahora recorre 3X más rápido sus campañas.',
        link: '/casos-de-exito/mornese/',
        name: 'Noelia Molina',
        job: 'Gerente de Planeamiento y Estrategias',
        foto1: FotoMorneseDesktop,
        foto2: FotoMorneseMobile
    },
    cyc: {
        tag: 'Atención al cliente | Marketing y ventas',
        description: ' Grupo CyC, ha mejorado sus principales indicadores de ecommerce con Beex, mejorando su estrategia de remarketing y crosseling, logrando aumentar en +40% sus ventas digitales.',
        link: '/casos-de-exito/cyccomputer/',
        name: 'Oskar Martinez',
        job: 'Marketing Leader',
        foto1: FotoCyCDesktop,
        foto2: FotoCyCMobile
    },
    netcall: {
        tag: 'Contact Center | Televentas',
        description: 'Mira cómo la operación de Netcall Perú, administra sus campañas de más de 300 teleoperadores con Beex, logrando asi aumentar su contactabilidad en +25% y duplicar sus ventas mensuales.',
        link: '/casos-de-exito/netcall-peru/',
        name: 'Humberto Chumán',
        job: 'Jefe de Proyectos en Netcal',
        foto1: FotoNetcallPeruDesktop,
        foto2: FotoNetcallPeruMobile
    },
    costagas: {
        tag: 'Masivo | Atención al cliente | Marketing y ventas',
        description: 'Costagas, reconocida marca de la industria energética, eligió a Beex para automatizar sus comunicaciones. Resultados: 1.3x más ventas y reducción a 1min tiempo de primera respuesta.',
        link: '/casos-de-exito/costagas/',
        name: 'Natalia Pachas',
        job: 'Jefa de Marketing de Costagas',
        foto1: FotoCostagasDesktop,
        foto2: FotoCostagasMobile
    },
    escampa: {
        tag: 'Fintech | Atención al cliente ',
        description: 'Escampa, fintech mexicana que brinda soluciones financieras para aliviar el sobre endeudamiento, ha mejorado en +30% su CSAT con la ayuda de Beex, sumando automatización y comunicaciones masivas por WhatsApp, a través del API oficial.',
        link: '/casos-de-exito/escampa/',
        name: 'Gabriel Gmora Backa',
        job: 'Socio Fundador de Escampa',
        foto1: FotoEscampaDesktop,
        foto2: FotoEscampaMobile
    },
    gualda: {
        tag: 'Educación | Marketing y ventas',
        description: 'Descubre como una empresa de crecimiento acelerado ha logrado mejorar la tasa de conversión de leads digitales hasta en +27% con Beex.',
        link: '/casos-de-exito/gualda/',
        name: 'Axel Gualda',
        job: 'CEO & Founder de Gualda Training',
        foto1: FotoGualdaDesktop,
        foto2: FotoGualdaMobile
    },
    recupera: {
        tag: 'Contact Center | Cobranzas',
        description: 'Mira cómo Recupera, grupo líder de cobranzas en Perú, aumento en +90% su tasa de adherencia y +97% su tasa de ocupación con el marcador Predictivo con Machine Learning de Beex.',
        link: '/casos-de-exito/grupo-recupera/',
        name: 'Marco Salina',
        job: 'Gerente Comercial de Recupera',
        foto1: FotoRecuperaDesktop,
        foto2: FotoRecuperaMobile
    },
    enseña: {
        tag: 'ONG | Telemarketing',
        description: 'Enseña Perú, ha logrado aumentar la cantidad de donantes a su movimiento social que transforma la educación de los niños más necesitados. Mira como Beex los ha ayudado con este fin.',
        link: '/casos-de-exito/ensena-peru/',
        name: 'Omar García',
        job: 'Coordinador del Programa de Suscriptores en Enseña Peru',
        foto1: FotoEnseñaDesktop,
        foto2: FotoEnseñaMobile
    },
}

export default customer