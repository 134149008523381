import React from "react"
import StructurePageCasoExito from "@components/pageCasosdeExito/template/templatePageCasoExito"
import { Helmet } from "react-helmet"

const PageCasoExito = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Beex | Casos de exito [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Presentamos a algunos de nuestros clientes y su testimonio sobre cómo transformaron su negocio gracias a los productos de Beex." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Experiencia de los clientes con los productos de Beex" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta property="og:description" content="Presentamos a algunos de nuestros clientes y su testimonio sobre cómo transformaron su negocio gracias a los productos de Beex." />
    </Helmet>
    <StructurePageCasoExito location={location} />
  </div>
)

export default PageCasoExito
